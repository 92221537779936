import axios from 'axios';
import { setAlert } from './alert';
import {
  EVENT_SET_ACTIVE,
  EVENT_CLEAR_ACTIVE,
  GET_EVENTS,
  EVENT_ERROR,
  ADD_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_ERROR,
  REMOVE_ERROR,
} from './types';
import apiUrl from '../config';

// Helper function to serialize date fields
const serializeEvent = (event) => ({
  ...event,
  start: event.start.toISOString(),
  end: event.end.toISOString(),
});

// Helper function to deserialize date fields
const deserializeEvent = (event) => ({
  ...event,
  start: new Date(event.start),
  end: new Date(event.end),
});

// Prepare events (deserialize)
const prepareEvents = (events = []) => {
  return events.map((event) => ({
    ...event,
    start: new Date(event.start),
    end: new Date(event.end),
  }));
};

// Get events
export const getEvents = () => async (dispatch) => {
  try {
    const res = await axios.get(`${apiUrl}/api/events`);

    if (res.data.ok) {
      // const events = prepareEvents(res.data.events).map((event) => ({
      //   ...event,
      //   start: event.start.toISOString(),
      //   end: event.end.toISOString(),
      // }));
      const events = prepareEvents(res.data.events);
      dispatch({
        type: GET_EVENTS,
        payload: events,
      });
    } else {
      dispatch(setAlert('Events are unable to load', 'danger'));
    }
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Add event
export const addEvent = (formData) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post(`${apiUrl}/api/events`, formData, config);

    dispatch({
      type: ADD_EVENT,
      payload: res.data,
    });

    dispatch(setAlert('Event Created', 'success'));
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// update event
export const updateEvent = (formData) => async (dispatch) => {
  console.log(formData);
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.put(
      `${apiUrl}/api/events/${formData._id}`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_EVENT,
      payload: res.data,
    });

    dispatch(setAlert('Event Updated', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

// Delete event
export const deleteEvent = () => async (dispatch, getState) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const id = getState().calendar.activeEvent._id;
  try {
    await axios.delete(`${apiUrl}/api/events/${id}`, config);

    dispatch({
      type: DELETE_EVENT,
      payload: id,
    });

    dispatch(setAlert('Event Removed', 'success'));

    // Fetch the updated events after deletion
    dispatch(getEvents());
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const eventSetActive = (event) => ({
  type: EVENT_SET_ACTIVE,
  payload: event,
});

export const eventClearActive = () => ({
  type: EVENT_CLEAR_ACTIVE,
});

export const setError = (err) => ({
  type: SET_ERROR,
  payload: err,
});

export const removeError = () => ({
  type: REMOVE_ERROR,
});

export const openModal = () => ({
  type: OPEN_MODAL,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
