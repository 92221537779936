import {
  EVENT_SET_ACTIVE,
  EVENT_CLEAR_ACTIVE,
  GET_EVENTS,
  EVENT_ERROR,
  ADD_EVENT,
  UPDATE_EVENT,
  DELETE_EVENT,
  SET_ERROR,
  REMOVE_ERROR,
  OPEN_MODAL,
  CLOSE_MODAL,
} from '../actions/types';

const initialState = {
  events: [],
  activeEvent: null,
  modalOpen: false,
  msgError: '',
};

const deserializeEvent = (event) => ({
  ...event,
  start: new Date(event.start),
  end: new Date(event.end),
});

const eventReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EVENTS:
      return {
        ...state,
        events: [...payload],
      };

    case EVENT_SET_ACTIVE:
      return {
        ...state,
        activeEvent: payload,
      };

    case EVENT_CLEAR_ACTIVE:
      return {
        ...state,
        activeEvent: null,
      };

    case ADD_EVENT:
      return {
        ...state,
        events: [...state.events, payload],
      };

    case UPDATE_EVENT:
      return {
        ...state,
        events: state.events.map((event) =>
          event.id === payload.id ? payload : event
        ),
      };

    // case DELETE_EVENT:
    //   return {
    //     ...state,
    //     events: state.events.filter(
    //       (event) => event.id !== state.activeEvent.id
    //     ),
    //     activeEvent: null,
    //   };

    case DELETE_EVENT:
      return {
        ...state,
        events: state.events.filter((event) => event.id !== payload),
        activeEvent: null,
      };

    case EVENT_ERROR:
      return {
        ...state,
        error: payload,
      };

    case SET_ERROR:
      return {
        ...state,
        msgError: payload,
      };

    case REMOVE_ERROR:
      return {
        ...state,
        msgError: '',
      };

    case OPEN_MODAL:
      return {
        ...state,
        modalOpen: true,
      };

    case CLOSE_MODAL:
      return {
        ...state,
        modalOpen: false,
      };

    default:
      return state;
  }
};

export default eventReducer;
