import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MainPage from '../mainpage/MainPage';
import { getProfiles } from '../../actions/profile';
import BirthdayItem from './BirthdayItem';
import Spinner from '../../components/shared/Spinner';
import formatDate from '../../utils/formatDate';

import './Home.css';

const Home = ({ getProfiles, profile: { profiles, loading } }) => {
  useEffect(() => {
    getProfiles();
  }, [getProfiles]);

  const getBirthdayData = (profiles) => {
    const today = new Date();
    const nextWeek = new Date(today);
    nextWeek.setDate(today.getDate() + 7);

    const todaysBirthdays = [];
    const upcomingBirthdays = [];

    profiles.forEach((profile) => {
      if (!profile.user) return; // Skip if profile.user is null
      // Convert the datetime to Central Time Zone
      const dob = new Date(formatDate(profile.dob));
      const dobCentral = new Date(
        dob.toLocaleString('en-US', { timeZone: 'America/Chicago' })
      );
      const dobThisYear = new Date(
        today.getFullYear(),
        dobCentral.getMonth(),
        dobCentral.getDate()
      );
      // console.log('doThisYear', dobThisYear);
      if (dobThisYear.toDateString() === today.toDateString()) {
        todaysBirthdays.push(profile);
      } else if (dobThisYear > today && dobThisYear < nextWeek) {
        upcomingBirthdays.push(profile);
      }
    });

    return { todaysBirthdays, upcomingBirthdays };
  };

  const { todaysBirthdays, upcomingBirthdays } = getBirthdayData(profiles);

  // console.log('Todays', todaysBirthdays);
  // console.log('Upcoming', upcomingBirthdays);

  return (
    <MainPage>
      {/* <section className='container'> */}
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className='wrapper'>
            <div className='event'>
              <div>
                <h4 className='text-primary py'>Display Events</h4>
              </div>
            </div>
            <div className='birthday'>
              <div>
                <h4 className='text-primary py'>Today's Birthday(s)</h4>
                <div className='todays-birthdays'>
                  {todaysBirthdays.length > 0 ? (
                    todaysBirthdays.map((profile) => (
                      <BirthdayItem key={profile._id} profile={profile} />
                    ))
                  ) : (
                    <h4 className='text-danger py'>
                      No today's birthday's found...
                    </h4>
                  )}
                </div>
              </div>
              <div>
                <h4 className='text-primary py'>Upcoming Birthday(s)</h4>
                <div className='upcoming-birthdays'>
                  {upcomingBirthdays.length > 0 ? (
                    upcomingBirthdays.map((profile) => (
                      <BirthdayItem key={profile._id} profile={profile} />
                    ))
                  ) : (
                    <h4 className='text-danger py'>
                      No upcoming birthday's found...
                    </h4>
                  )}
                </div>
              </div>
            </div>
            <div className='founder'>
              <div>
                <h4 className='text-primary py'>Display Founder members</h4>
              </div>
            </div>
            <div className='excutive'>
              <div>
                <h4 className='text-primary py'>Display Executive members</h4>
              </div>
            </div>
            <div className='advisory'>
              <div>
                <h4 className='text-primary py'>Display Advisory members</h4>
              </div>
            </div>
            <div className='general'>
              <div>
                <h4 className='text-primary py'>Display General members</h4>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      {/* </section> */}
    </MainPage>
  );
};

Home.propTypes = {
  getProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  // console.log(state.profile);
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps, { getProfiles })(Home);
