import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }
  return age;
};

const BirthdayItem = ({ profile }) => {
  if (!profile || !profile.user) {
    return;
  }

  const {
    user: { _id, name, avatar },
    dob,
  } = profile;

  const age = calculateAge(dob);
  // console.log(age);

  return (
    <div className='profile bg-light'>
      <Link to={`/profile/${_id}`}>
        <img src={avatar} alt='' className='round-img' />
      </Link>
      <div>
        <h6>{name}</h6>
      </div>
      <div>
        <h6>{age} yrs</h6>
      </div>
    </div>
  );
};

BirthdayItem.propTypes = {
  profile: PropTypes.shape({
    user: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      avatar: PropTypes.string.isRequired,
    }),
    dob: PropTypes.string.isRequired,
  }).isRequired,
};

export default BirthdayItem;
