import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import BrandName from '../brandname/BrandName';

import './Navbar.css';
import GuestLinks from './GuestLinks';
import AdminLinks from './AdminLinks';
import AuthLinks from './AuthLinks';

const Navbar = ({ isAuthenticated, roles, loading, logout }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const getLinks = (isAuthenticated, roles, logout, menuOpen) => {
    if (!isAuthenticated) {
      return <GuestLinks menuOpen={menuOpen} />;
    }

    if (roles && roles.Admin === 5001) {
      return <AdminLinks logout={logout} menuOpen={menuOpen} />;
    }

    return <AuthLinks logout={logout} menuOpen={menuOpen} />;
  };

  return (
    <nav className='bg-primary'>
      <h1>
        <Link to='/'>
          <BrandName />
        </Link>
      </h1>
      <div
        className='menu'
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      {!loading && (
        <Fragment>
          {getLinks(isAuthenticated, roles, logout, menuOpen)}
        </Fragment>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  isAuthenticated: PropTypes.bool,
  roles: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const roles = state.auth.user ? state.auth.user.roles : {};
  return {
    isAuthenticated: state.auth.isAuthenticated || false,
    roles,
    loading: state.auth.loading,
  };
};

export default connect(mapStateToProps, { logout })(Navbar);
